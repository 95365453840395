import React from "react"
import PropTypes from "prop-types"

import dayjs from "dayjs"
import RelativeTime from "dayjs/plugin/relativeTime"

import "./layout.css"

dayjs.extend(RelativeTime);

const Layout = ({ children }) => {
  const [hasRan, setHasRan] = React.useState(false);
  const [screenSize, setScreenSize] = React.useState({
    height: 0,
    width: 0,
  });
  const updateScreenSize = () => {
    setScreenSize({ width: window.innerWidth, height: window.innerHeight })
  }

  React.useEffect(() => {
    if (!hasRan) {
      setHasRan(true)
      updateScreenSize()
    }
    window.addEventListener("resize", updateScreenSize)
    return () => {
      window.removeEventListener("resize", updateScreenSize)
    }
  }, [screenSize,hasRan])

  let poster = `https://res.cloudinary.com/dktkutuub/image/upload/c_fill,h_${screenSize.height},w_${screenSize.width},g_auto/v1612982282/la_maree/poster_wbwrax.jpg`;
  let sourceMP4 = "";
  let sourceWebM = "";
  let sourceOGV = "";

  if (screenSize.width > screenSize.height) {
    if (screenSize.width > 1280) {
      sourceMP4 = "https://res.cloudinary.com/dktkutuub/video/upload/v1612980588/la_maree/trailer_1080_qof0gg.mp4"
      sourceOGV = "https://res.cloudinary.com/dktkutuub/video/upload/v1612980588/la_maree/trailer_1080_qof0gg.ogv"
      sourceWebM = "https://res.cloudinary.com/dktkutuub/video/upload/v1612980576/la_maree/trailer_1080_fsebex.webm"
    } else {
      sourceMP4 = "https://res.cloudinary.com/dktkutuub/video/upload/v1612980581/la_maree/trailer_720_uzh9ui.mp4"
      sourceOGV = "https://res.cloudinary.com/dktkutuub/video/upload/v1612980581/la_maree/trailer_720_uzh9ui.ogv"
      sourceWebM = "https://res.cloudinary.com/dktkutuub/video/upload/v1612980481/la_maree/trailer_720_vt44ok.webm"
    }
  } else {
    if (screenSize.width > 400) {
      sourceMP4 = "https://res.cloudinary.com/dktkutuub/video/upload/v1612980982/la_maree/trailer_600_portrait_atobze.mp4"
      sourceOGV = "https://res.cloudinary.com/dktkutuub/video/upload/v1612980982/la_maree/trailer_600_portrait_atobze.ogv"
      sourceWebM = "https://res.cloudinary.com/dktkutuub/video/upload/v1612980970/la_maree/trailer_600_portrait_blu5xr.webm"
    } else {
      sourceMP4 = "https://res.cloudinary.com/dktkutuub/video/upload/v1612980955/la_maree/trailer_400_portrait_n48tea.mp4"
      sourceOGV = "https://res.cloudinary.com/dktkutuub/video/upload/v1612980955/la_maree/trailer_400_portrait_n48tea.ogv"
      sourceWebM = "https://res.cloudinary.com/dktkutuub/video/upload/v1612980964/la_maree/trailer_400_portrait_oob5j0.webm"
    }
  }

  return (
    <>
      <div className="body-container">
        <div className="video-container">
          <video key={sourceWebM} autoPlay loop muted poster={poster} id="bgvid" className="video-bg" onEnded={() => alert("hey")}>
            <source src={sourceWebM} type="video/webm"/>
            <source src={sourceMP4} type="video/mp4"/>
            <source src={sourceOGV} type="video/ogg"/>
          </video>
          <div className="gradient-overlay"></div>
        </div>
        <main>{children}</main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
